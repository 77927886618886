import { initializeApp } from "firebase/app";
let config = {
    apiKey: "AIzaSyDZGQHbzHC50UabJKRXbEt3jVL1Ll3A2J8",
    authDomain: "rice-website.firebaseapp.com",
    databaseURL: "https://rice-website.firebaseio.com",
    projectId: "rice-website",
    storageBucket: "rice-website.appspot.com",
    messagingSenderId: "95107576656",
    appId: "1:95107576656:web:50536abafe8a002426b255"
  };

const firebaseApp = initializeApp(config);

export default firebaseApp;

